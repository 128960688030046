<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title">
				<span>Bot</span>
			</div>
			<div class="add_main hidden_scroll">
				<p class="font_weight_bold" style="height: 20px"></p>
				<p class="font_weight_bold" style="font-size: 20px; color: red"
					>1、请注意，添加的bot信息，必须为我们已有文章或者活动的关联信息</p
				>
				<p class="font_weight_bold" style="height: 20px"></p>

				<div class="margin_top_20">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<el-form-item label="封面图片" prop="pic">
							<div class="upload_img_div" v-if="formData.pic">
								<img
									style="width: 80%"
									:src="formData.pic"
									@click="uploadPicture(formData.pic)"
								/>
								<div class="upload_img_del">
									<img
										@click="removePicture"
										src="../../../assets/images/upload_img_del.png"
									/>
								</div>
							</div>

							<div v-else>
								<div class="el-upload__text">
									<img
										style="width: 100px"
										src="../../../assets/images/uploadImg.png"
										alt=""
										@click="uploadPicture(formData.pic)"
									/>
									<br />
									<em>点击加号上传</em></div
								>
							</div>
							<!-- 剪裁组件弹窗 -->
							<el-dialog
								title="裁切图片"
								:visible.sync="cropperModel"
								width="1300px"
								center
								:append-to-body="true"
							>
								<template v-if="cropperModel">
									<cropper-image-new
										:Name="cropperName"
										:type="scale"
										@uploadImgSuccess="handleUploadSuccess"
										ref="child"
									>
									</cropper-image-new
								></template>
							</el-dialog>
						</el-form-item>

						<el-form-item label="" prop="">
							<p class="font_weight_bold" style="font-size: 20px; color: black"
								>活动推荐（最多推荐5个活动）</p
							>
						</el-form-item>
						<el-form-item label="活动信息">
							<div
								class="bot_detail_bg"
								v-for="(item, index) in formData.items[0].list"
								:key="index"
							>
								<div class="bot_detail_content">
									<el-input
										v-model="item.name"
										:placeholder="'请输入吸引人的标题 ' + (index + 1)"
									>
									</el-input>
									<el-input
										v-model="item.url"
										placeholder="输入ME Event特定活动链接"
										style="margin-top: 20px"
									>
									</el-input>
								</div>
								<div class="bot_detail_del">
									<img
										v-if="index > 0"
										style="width: 100px"
										src="../../../assets/images/del.png"
										alt=""
										@click="delEvent(index)"
									/>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="" prop="">
							<div style="display: flex; width: 300px; align-items: center">
								<img
									style="width: 30px; height: 30px; margin-right: 10px"
									src="../../../assets/images/add.png"
									alt=""
									@click="addEvent()"
								/>
								<p
									class="font_weight_bold"
									style="font-size: 20px; color: #1296db"
									@click="addEvent()"
								>
									新增推荐活动
								</p>
							</div>
						</el-form-item>
						<el-form-item label="">
							<p class="font_weight_bold" style="font-size: 20px; color: black">
								AMA推荐（最多推荐5个活动）
							</p>
						</el-form-item>

						<el-form-item label="推荐信息">
							<div
								class="bot_detail_bg"
								v-for="(item, index) in formData.items[1].list"
								:key="index"
							>
								<div class="bot_detail_content">
									<el-input
										v-model="item.name"
										:placeholder="
											'为这场AMA/抽奖福利想一个吸引人的标题吧 ' + (index + 1)
										"
									>
									</el-input>
									<el-input
										v-model="item.url"
										placeholder="输入ME AMA推特链接"
										style="margin-top: 20px"
									>
									</el-input>
								</div>
								<div class="bot_detail_del">
									<img
										v-if="index > 0"
										style="width: 100px"
										src="../../../assets/images/del.png"
										alt=""
										@click="delAMA(index)"
									/>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="" prop="">
							<div style="display: flex; width: 300px; align-items: center">
								<img
									style="width: 30px; height: 30px; margin-right: 10px"
									src="../../../assets/images/add.png"
									alt=""
									@click="addAMA()"
								/>
								<p
									class="font_weight_bold"
									style="font-size: 20px; color: #1296db"
									@click="addAMA()"
								>
									新增推荐AMA
								</p>
							</div>
						</el-form-item>
						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									class="custom_btn_width_100"
									@click="$router.push({ path: '/message/events' })"
									>返回</el-button
								>
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="save(0)"
									style="background: gray"
								>
									仅保存
								</el-button>
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="save(1)"
									>推送Bot</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import { validateNull } from "@/utils/validate";
import { updateBotDetai, botDetail } from "@/api/article";
export default {
	components: {},
	name: "",
	data() {
		return {
			rule: {
				title: [{ validator: validateNull, trigger: "blur", required: true }],
				desc: [{ validator: validateNull, trigger: "blur", required: true }],
				pic: [{ validator: validateNull, trigger: "blur", required: true }],
				contentId: [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				url: [{ validator: validateNull, trigger: "blur", required: true }],
				type: [{ validator: validateNull, trigger: "change", required: true }],
			},
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
			scale: "0",
			formData: {
				title: "",
				contentId: "",
				type: "1",
				id: "",
				pic: "",
				desc: "",
				url: "",
				status: "0",
				bot: 0,
				items: [
					{ title: "每日精选活动/福利推送", list: [{ name: "", url: "" }] },
					{ title: "每日精选活动/福利推送", list: [{ name: "", url: "" }] },
				],
			},
		};
	},
	created() {
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.details();
		}
	},

	mounted() {},
	methods: {
		delEvent(index) {
			if (this.formData.items[0].list.length > 1) {
				this.formData.items[0].list.splice(index, 1);
			}
		},
		addEvent() {
			this.formData.items[0].list.push({ name: "", url: "" });
		},
		delAMA(index) {
			if (this.formData.items[1].list.length > 1) {
				this.formData.items[1].list.splice(index, 1);
			} else {
				console.log(this.formData.items[1].list);
			}
		},
		addAMA() {
			this.formData.items[1].list.push({ name: "", url: "" });
		},
		removePicture() {
			this.formData.pic = "";
		},
		//封面设置
		uploadPicture(name) {
			if (this.$route.query.type == "detail") {
				// 详情不支持编辑图片
			} else {
				this.cropperName = name;
				this.type = "1";
				this.cropperModel = true;
			}
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			if (
				this.cropperName != null &&
				this.cropperName.indexOf("blob:http") != -1
			) {
				this.handleSuccess(data);
			} else {
				this.formData.pic = data.url;
			}
		},
		details() {
			const that = this;
			botDetail({ id: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						that.formData.type = res.data.type.toString();
						that.formData.title = res.data.title.toString();
						that.formData.desc = res.data.desc.toString();
						that.formData.contentId = res.data.contentId.toString();
						that.formData.id = res.data.id.toString();
						that.formData.pic = res.data.pic.toString();
						that.formData.url = res.data.url.toString();
						that.formData.pic = res.data.pic.toString();
						that.formData.status = res.data.status.toString();
						that.formData.items = JSON.parse(res.data.items);
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		save(bot) {
			let router = this.$route.query.type;
			if (router == undefined || router.length == 0) {
				this.formData.id = "";
			} else if (router == "copy") {
				this.formData.id = "";
			} else if (router == "detail") {
			} else if (router == "edit") {
			} else {
			}

			this.formData.status = 0;

			this.formData.bot = bot;
			this.formData.status = bot;

			updateBotDetai(this.formData)
				.then((res) => {
					if (res.code == 200) {
						this.$router.push({ path: "/information/telegram" });
					} else {
					}
				})
				.catch((error) => {});
		},
		clearFormAdd() {
			for (let key in this.formData) {
				this.formData[key] = "";
			}
			this.formData.type = "1";
			this.formData.items = [
				{ title: "每日精选活动/福利推送", list: [{ name: "", url: "" }] },
				{ title: "ME 独家AMA", list: [{ name: "", url: "" }] },
			];
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}
</style>
<style>
.distpicker-address-wrapper select {
	width: 255px !important;
	padding: 0px 10px !important;
	height: 35px !important;
	font-size: 18px !important;
	line-height: 35px !important;
}
</style>
<!-- ac_create_ticket -->
<style scoped>
.ac_create_ticket {
	margin-top: 10px;
}
.ac_create_ticket_header {
	height: 60px;
	border-radius: 10px;
	background: rgba(240, 240, 240, 0.5);
	display: flex;
	align-items: center;
}

.ac_create_ticket_header_1,
.ac_create_ticket_header_2,
.ac_create_ticket_header_3,
.ac_create_ticket_header_4,
.ac_create_ticket_header_5 {
	width: 20%;
	text-align: center;
	align-content: center;
}

.ac_create_ticket_add {
	display: flex;
	margin-top: 20px;
	align-items: center;
}

.ac_create_ticket_add span {
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_create_ticket_add_name {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_type {
	text-align: center;
	align-content: center;
	width: 15%;
	padding-left: 1%;
	padding-right: 1%;
	margin-left: 2%;
	margin-right: 2%;
	height: 50px;
	border-radius: 10px;
	opacity: 1;
	border: 1px solid #333333;
}

.ac_create_ticket_add_type_down {
	width: 100%;
	height: 50px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_create_ticket_add_type_down span {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.ac_create_ticket_down_menu {
	width: 160px;
}

.ac_create_ticket_down_menu_item {
	height: 40px;
	/* text-align: center; */
	align-content: center;
	align-items: center;
}

.ac_create_ticket_add_code {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_price {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_num {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}

.ac_create_ticket_add_num_del {
	width: 34px;
	height: 34px;
	opacity: 1;
}
.ac_create_ticket_add_num_del img {
	width: 100%;
	height: 100%;
}
.upload_img_div {
	display: flex;
}
.upload_img_del {
	margin-left: -15px;
	margin-top: -15px;
}
.upload_img_del img {
	width: 30px;
	height: 30px;
}

.bot_detail_bg {
	background: #aaaaaa;
	padding: 20px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}
.bot_detail_content {
	width: 95%;
}
.bot_detail_del {
	margin-left: 30px;
	width: 50px;
}

.bot_detail_del img {
	width: 30px !important;
	height: 30px;
}
</style>
